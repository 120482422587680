<template>
  <div
    class="hm-table-button"
    :class="{
      active,
      'hm-table-button--clicable': isClicable,
    }"
    v-bind="$attrs"
  >
    <HMIcon v-if="icon" :icon="icon" />
  </div>
</template>

<script>
export default {
  props: {
    icon: {
      type: Array,
      default: () => [],
    },
    active: {
      type: Boolean,
      default: false,
    },
    hoverable: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isClicable() {
      return (this.$attrs && this.$attrs.click) || this.hoverable;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/components-variables.scss';

.hm-table-button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.25rem 0.25rem;
  margin: 0 0.25rem;
  color: $medium-gray;
  cursor: pointer;
  &.active {
    color: var(--brand-color, $coral);
  }
}

.hm-table-button--clicable {
  &:hover {
    color: var(--brand-color, $coral);
  }
}
</style>
